<template>
  <div>
    <div v-if="initialized">
      <FullLoader v-if="showLoader" />
      <div v-else-if="!location.disabled">
        <h3 class="display-4 text-center mb-4 question-title">
          {{ $t('leave_survey.rating.greeting') }}
        </h3>

        <FeedbackOptions @selected="selectedOption" />
      </div>
      <div v-else>
        <Alert type="alert-danger">
          {{ $t('common.errors.casual.default') }}
        </Alert>
      </div>
    </div>
    <div class="d-flex justify-content-center" v-else>
      <div class="loader loader-primary"></div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, createNamespacedHelpers } from 'vuex'
import FeedbackOptions from '@/components/Modules/Locations/FeedbackOptions'
import SurveyService from '@/services/SurveyService'
import Alert from '@/components/MainContent/Alert'
import FullLoader from '@/components/MainContent/FullLoader'
const SurveyModule = createNamespacedHelpers('SurveyModule')
const I18nModule = createNamespacedHelpers('i18n')

export default {
  name: 'LeaveSurvey',
  components: {
    FeedbackOptions,
    FullLoader,
    Alert,
  },
  data: () => ({
    showLoader: false,
    initialized: false,
  }),
  computed: {
    ...mapState(['customerId', 'location', 'mixpanelPayload', 'source']),
    ...mapGetters(['selectOrderId', 'selectRawExperienceId', 'selectRating']),
    ...I18nModule.mapGetters(['selectLastTemplateFilters']),
  },
  async mounted() {
    if (!this.selectLastTemplateFilters?.locationId) {
      await this.fetchLanguage({
        ...this.selectLastTemplateFilters,
        locationId: this.$route.params?.locationId,
      })
    }
    this.$mixpanel.track('Survey Rating Prompt', {
      ...this.mixpanelPayload,
      source: this.source,
    })
    const rating = parseInt(
      this.$route.params.rating || this.$route.query.rating,
      10,
    )
    if (!isNaN(rating) || !isNaN(parseInt(this.selectRating))) {
      this.selectedOption(rating)
    }
    this.initialized = true
  },
  methods: {
    ...SurveyModule.mapActions(['createSurvey']),
    ...I18nModule.mapActions(['fetchLanguage']),
    async selectedOption(rating) {
      this.$emit('hide-branding', true)
      this.showLoader = true
      const { _id: locationId, company } = this.location

      let surveyId = null
      if (this.selectRawExperienceId) {
        if (this.selectRating) {
          rating = this.selectRating
        }
        const response = await this.createSurvey({
          locationId,
          rating,
          rawExperienceId: this.selectRawExperienceId,
          customerId: this.customerId,
          orderId: this.selectOrderId,
          meta: { ...this.$route.query }
        })
        surveyId = response.data.surveyId
      } else {
        const response = await SurveyService.create({
          rating,
          locationId,
          source: this.source,
          companyId: company._id,
          customerId: this.customerId,
          orderId: this.selectOrderId,
          meta: { ...this.$route.query }
        })
        surveyId = response.body.surveyId
      }

      this.$mixpanel.track('Submitted Rating', {
        ...this.mixpanelPayload,
        source: this.source,
        rating,
      })

      if (parseInt(rating) === 5) {
        if (
          [
            '5d02afeee2e45649bb4b56fb',
            '65ce6fe5e8e3269a0074d43f',
            '65cf8b41488b534b1c9644c8',
          ].includes(company._id.toString())
        ) {
          this.$router.push({
            name: 'leave-review',
            params: { locationId },
            query: {
              language: this.$route.query.language,
              customerName: this.$route.query.customerName,
              customerPhone: this.$route.query.customerPhone,
              customerEmail: this.$route.query.customerEmail,
              checkNum: this.$route.query.checkNum,
            },
          })
        } else {
          this.$router.replace({
            name: 'giveaway',
            params: { locationId, customerId: this.customerId },
            query: {
              sid: surveyId,
              source: this.source,
              language: this.$route.query.language,
              customerName: this.$route.query.customerName,
              customerPhone: this.$route.query.customerPhone,
              customerEmail: this.$route.query.customerEmail,
              checkNum: this.$route.query.checkNum,
            },
          })
        }
      } else {
        this.$router.replace({
          name: 'leave-feedback',
          params: { surveyId, rating },
          query: {
            source: this.source,
            language: this.$route.query.language,
            customerName: this.$route.query.customerName,
            customerPhone: this.$route.query.customerPhone,
            customerEmail: this.$route.query.customerEmail,
            checkNum: this.$route.query.checkNum,
          },
        })
      }
      this.showLoader = false
      this.$emit('hide-branding', false)
    },
  },
}
</script>

<style scoped lang="scss"></style>
