<template>
  <div>
    <FeedbackOptions :focused-value="rating" @selected="selectedOption" />

    <form @submit.prevent="leaveFeedback">
      <div
        class="feedback-card card"
        :class="['rating-' + $route.params.rating + '-' + numOptions]"
      >
        <div class="card-header text-left">
          <h4 id="feedback" class="card-header-title">{{ feedbackMessage }}</h4>
        </div>
        <div class="text-center card-body">
          <div>
            <textarea
              v-model="feedback"
              aria-labelledby="feedback"
              class="form-control form-control-flush"
              rows="5"
              :placeholder="$t('leave_feedback.form.text_area.placeholder')"
            ></textarea>
          </div>
        </div>

        <div class="card-footer">
          <div class="d-flex mb-2 ml-1" style="height: 15px">
            <div class="mt--2 mr-2" style="font-size: 13px">
              {{ $t('leave_feedback.form.detail_meter.title') }}
            </div>
            <div class="w-50">
              <div class="progress" style="max-height: 10px">
                <div
                  class="progress-bar"
                  role="progressbar"
                  style="background-color: var(--primary)"
                  :style="`width: ${progress}%`"
                ></div>
              </div>
            </div>
            <div v-if="progress === 100" class="ml-2 mt--2">🎉</div>
          </div>

          <button
            type="submit"
            class="btn btn-lg btn-block btn-primary mb-0"
            :class="{ 'is-loading': isLoading }"
            :disabled="!formValid || isLoading"
          >
            {{ $t('leave_feedback.form.button.submit') }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FeedbackOptions from '@/components/Modules/Locations/FeedbackOptions'
import SurveyService from '@/services/SurveyService'
import _ from 'lodash'

export default {
  name: 'LeaveFeedback',
  components: {
    FeedbackOptions,
  },
  computed: {
    ...mapState([
      'location',
      'mixpanelPayload',
      'customer',
      'source',
      'customOptions',
    ]),
    progress() {
      if (this.feedback.trim() === '') {
        return 0
      } else {
        const wordCount = this.feedback.trim().split(/\s+/).length
        const progress = (wordCount / 25) * 100
        return progress > 100 ? 100 : progress
      }
    },
    numOptions() {
      return !_.isEmpty(this.customOptions)
        ? this.customOptions.options.length
        : 5
    },
    formValid() {
      return this.feedback.trim().length > 3
    },
    rating() {
      return parseInt(this.$route.params.rating, 10)
    },
    feedbackMessage() {
      switch (this.$route.params.rating) {
        case 1:
          return this.$t('leave_feedback.form.title.rating_1')
        case 2:
          return this.$t('leave_feedback.form.title.rating_2')
        case 3:
          return this.$t('leave_feedback.form.title.rating_3')
        default:
          return this.$t('leave_feedback.form.title.rating_4')
      }
    },
  },
  data: () => ({
    isLoading: false,
    feedback: '',
    ovationLocationId: '5de98d922bb69639e2cdfe17',
  }),
  async mounted() {
    this.$mixpanel.track('Leave Feedback Prompt', {
      ...this.mixpanelPayload,
    })
  },
  methods: {
    async selectedOption(rating) {
      const { surveyId } = this.$route.params
      const timeout = setTimeout(() => (this.isLoading = true), 500)

      await SurveyService.update({
        surveyId,
        fields: {
          rating,
        },
      })

      if (rating === 5) {
        this.routeToGiveaway(surveyId, this.location._id, this.customer._id)
      } else {
        this.$router.push({
          name: 'leave-feedback',
          params: { surveyId, rating },
          query: {
            source: this.source,
            language: this.$route.query.language,
            customerName: this.$route.query.customerName,
            customerPhone: this.$route.query.customerPhone,
            customerEmail: this.$route.query.customerEmail,
            checkNum: this.$route.query.checkNum,
          },
        })
      }

      clearTimeout(timeout)
      this.isLoading = false
    },
    async leaveFeedback() {
      const { surveyId, rating, customerId, companyId } = this.$route.params
      this.isLoading = true
      if (surveyId) {
        await SurveyService.update({
          surveyId,
          fields: {
            rating: this.rating,
            feedback: this.feedback,
          },
        })
        this.$mixpanel.track('Left Feedback', {
          ...this.mixpanelPayload,
          feedback: this.feedback,
        })
        this.routeToGiveaway(surveyId, this.location._id, this.customer._id)
      } else {
        let requiredSurvey
        const survey = await SurveyService.getSurvey({
          companyId: companyId,
          customerId: customerId,
        })
        const surveyList = survey.body.survey
        requiredSurvey = surveyList.find(
          (item) => item.location === this.ovationLocationId,
        )
        if (requiredSurvey) {
          await SurveyService.update({
            surveyId: requiredSurvey._id,
            fields: {
              rating: rating,
              feedback: this.feedback,
            },
          })
        } else {
          requiredSurvey = await SurveyService.create({
            rating,
            locationId: this.ovationLocationId,
            source: this.source,
            companyId: companyId,
            customerId: customerId,
          })
        }
        let idOfSurvey = requiredSurvey._id
          ? requiredSurvey._id
          : requiredSurvey.surveyId
        this.routeToGiveaway(idOfSurvey, this.ovationLocationId, customerId)
      }
    },
    routeToGiveaway(surveyId, locationId, customerId) {
      this.$router.push({
        name: 'giveaway',
        params: {
          locationId: locationId,
          customerId: customerId,
        },
        query: {
          source: this.source,
          sid: surveyId,
          prev: 'feedback',
          language: this.$route.query.language,
          customerName: this.$route.query.customerName,
          customerPhone: this.$route.query.customerPhone,
          customerEmail: this.$route.query.customerEmail,
          checkNum: this.$route.query.checkNum,
        },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.rating-1-5 {
  &::before,
  &::after {
    left: 8%;
  }
}
.rating-2-5 {
  &::before,
  &::after {
    left: 32%;
  }
}
.rating-3-5 {
  &::before,
  &::after {
    left: 50%;
  }
}
.rating-4-5 {
  &::before,
  &::after {
    left: 68%;
  }
}
.rating-5-5 {
  &::before,
  &::after {
    left: 92%;
  }
}
.rating-1-4 {
  &::before,
  &::after {
    left: 17%;
  }
}
.rating-2-4 {
  &::before,
  &::after {
    left: 39%;
  }
}
.rating-3-4 {
  &::before,
  &::after {
    left: 61%;
  }
}
.rating-4-4 {
  &::before,
  &::after {
    left: 83%;
  }
}
</style>
